const PRELOAD_HIT = 2

const catchGlobalScroll = e => {
	let { innerHeight, scrollY: currentScroll } = window

	if (currentScroll >= 10 && window.globalWatchOnScroll && window.globalWatchOnScroll.length > 0) {
		console.log('fire lazy load on scroll')
		let index_to_delete_scroll = []

		window.globalWatchOnScroll.map((item, index) => {
			console.log('lazy load on scroll', item)
			OnTargetView({ ...item, onScrollFire: true })
			index_to_delete_scroll.push(index)
		})

		window.globalWatchOnScroll = window.globalWatchOnScroll.filter(
			(e, i) => !index_to_delete_scroll.includes(i)
		)
	}

	if (window.globalWatch) {
		let index_to_delete = []
		window.globalWatch.map((item, index) => {
			if (item.onScroll && currentScroll <= 0) {
				return false
			}

			let box = item.element.getBoundingClientRect()

			// console.log(innerHeight * PRELOAD_HIT, Math.abs(box.top))

			let styles = getComputedStyle(item.element)

			if (styles.position === 'fixed' && styles.display === 'none') {
				index_to_delete.push(index)
				let itemRef = { ...item }
				setTimeout(() => {
					console.log(`progressive evaluate`, itemRef.element, ` of ${itemRef.targets}`)
					itemRef.evaluate(itemRef)
				}, 5000)
			} else if (innerHeight * PRELOAD_HIT >= Math.abs(box.top)) {
				console.log(`evaluate`, item.element, ` of ${item.targets}`)
				item.evaluate(item)
				index_to_delete.push(index)
			}
		})

		window.globalWatch = window.globalWatch.filter((e, i) => !index_to_delete.includes(i))
	}
	// console.log({ currentScroll })
}

export interface PreloadProps {
	elements: HTMLElement[] | NodeListOf<Element>
	evaluate: (item?: HTMLElement) => void
	targets: string | HTMLElement[] | NodeListOf<Element>
	element: Element | HTMLElement
	onScroll?: boolean
}

type OnTargetViewProps = {
	targets: string | HTMLElement[] | NodeListOf<Element>
	evaluate: (item?: HTMLElement) => void
	preload: (props: PreloadProps[]) => void
	onScroll?: boolean
	onScrollFire?: boolean
}
type OnTargetViewType = (props: OnTargetViewProps) => void

export const OnTargetView: OnTargetViewType = props => {
	const { targets, evaluate, preload, onScroll = false, onScrollFire = false } = props
	if (onScroll && !onScrollFire) {
		if (!window.globalWatchOnScroll) {
			window.globalWatchOnScroll = []
		}
		window.globalWatchOnScroll.push(props)
		return false
	}

	let elements = typeof targets === 'string' ? document.querySelectorAll(targets) : targets

	if (elements.length > 0) {
		console.log(`wait to load ${targets}`)
		elements.forEach(element => {
			console.log(`mount`, element, ` of ${targets}`)

			if (!window.globalWatch) {
				window.globalWatch = []
			}
			window.globalWatch.push({
				evaluate,
				element,
				elements,
				targets,
				onScroll,
			})

			if (preload) {
				console.log(`preload`, element, ` of ${targets}`)
				preload({
					evaluate,
					element,
					elements,
					targets,
				})
			}

			// if (!onScrollFire) {
			// 	catchGlobalScroll()
			// }
		})
	}
}

setTimeout(() => {
	catchGlobalScroll()
}, 100)

window.addEventListener('scroll', catchGlobalScroll)
