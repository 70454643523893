import { render } from 'react-dom'
import React, { lazy, Suspense, FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Loader } from '../components/Loader'
import { isExternalCalc } from '../helpers/isExternalCalc'
import { OnTargetView } from '../common/OnTargetView'
import { retry } from '../helpers/retry'
import isMobile from '../helpers/isMobile'

const MatrixForm = lazy(() => retry(() => import('../zones/Matrix')))
const EmbedMatrixForm = lazy(() => import('./make-embed-form-matrix'))

const MatrixSkeleton = () => {
	return (
		<div>
			<Skeleton height={isMobile() ? 302 : 306} />
		</div>
	)
}

OnTargetView({
	targets: '[data-render-form]',
	preload: ({ element }) => {
		render(<MatrixSkeleton />, element)
	},
	evaluate: ({ element }) => {
		if (isExternalCalc()) {
			if (element.hasAttribute('data-ref-id')) {
				window.localStorage.setItem('mc_ref_id', element.getAttribute('data-ref-id'))
			}
			render(
				<Suspense fallback={<MatrixSkeleton />}>
					<EmbedMatrixForm
						renderTo={element.getAttribute('data-render-to')}
						formMatch={element.hasAttribute('data-match')}
						formMatchBusiness={element.hasAttribute('data-match-business')}
						formChildren={element.hasAttribute('data-children')}
						formSingle={element.hasAttribute('data-single')}
						formFinance={element.hasAttribute('data-finance')}
						formSelfBrand={element.hasAttribute('data-self-brand')}
						disableAutoShow={element.hasAttribute('data-disable-auto-show')}
						hideMatchHeader={element.hasAttribute('data-hide-match-header')}
						refID={
							element.hasAttribute('data-ref-id')
								? parseInt(element.getAttribute('data-ref-id'), 10)
								: undefined
						}
					/>
				</Suspense>,
				element
			)
		} else {
			render(
				// <MatrixSkeleton count={5} />,
				<Suspense fallback={<MatrixSkeleton />}>
					<MatrixForm
						renderTo={element.getAttribute('data-render-to')}
						formMatch={element.hasAttribute('data-match')}
						formMatchBusiness={element.hasAttribute('data-match-business')}
						formChildren={element.hasAttribute('data-children')}
						formSingle={element.hasAttribute('data-single')}
						formFinance={element.hasAttribute('data-finance')}
						formSelfBrand={element.hasAttribute('data-self-brand')}
						disableAutoShow={element.hasAttribute('data-disable-auto-show')}
						hideMatchHeader={element.hasAttribute('data-hide-match-header')}
					/>
				</Suspense>,
				element
			)
		}
	},
})
